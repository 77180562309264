<template>
  <div class="forecastapply">
    <div class="bg">
      <div class="padding-20">
        <el-row>
          <el-col :span="24">
            <div class="reporttitle font-size-24 bold">{{reportTilte}}</div>
            <div
              class="flex-row align-items-center justify-center margin-bottom-50 subtitle"
            >
              <div class="margin-right-30">
                产品：<el-select v-model="productID" placeholder="请选择" :style="{width:swidth+'px'}"  @change="changeProduct" size="mini" class="viewproduct">
                  <el-option
                    v-for="item in options"
                    :key="item.productId"
                    :label="item.productName"
                    :value="item.productId"
                   
                  >
                  </el-option>
                </el-select>
              </div>
              <div>报告期：{{ reportdate }}</div>
            </div>
            <div class="column viewcolumn">
              <el-row>
                <el-col
                  :span="24"
                  class="font-size-18 bold line-height-large margin-top-15"
                >
                  历史回顾
                </el-col>
                <el-col
                  :span="24"
                  class="font-size-12 line-height-large  color-sub"
                >
                统计周期：{{historydata.reportPriod?historydata.reportPriod:''}}
                </el-col>
              </el-row>
              <el-row :gutter="20" type="flex" class="margin-top-5">
                <el-col :span="24">
                  <Chart :option="historychart_opt"></Chart>
                </el-col>
              </el-row>
              
              <el-row>
                <el-col :span="24" class="margin-top-5">
                  <div class="font-size-12  uetext" v-html="reportDes ? reportDes.hisdesc : ''"></div>
                </el-col>
              </el-row>
              <div class="border-top margin-tb-40"></div>
              <el-row>
                <el-col
                  :span="24"
                  class="font-size-18 bold line-height-large "
                >
                  预测结果
                </el-col>
                <el-col
                  :span="24"
                  class="font-size-12 line-height-large  color-sub"
                >
                  预测周期：{{resultdata.startDate?resultdata.startDate:''}}至{{resultdata.endDate?resultdata.endDate:''}}
                </el-col>
              </el-row>
              <el-row :gutter="20" type="flex" class="margin-top-5">
                <el-col :span="24">
                  <Chart :option="Resultchart_opt"></Chart>
                </el-col>
              </el-row>
              <div class="border-top margin-tb-40"></div>
              <el-row>
                <el-col
                  :span="24"
                  class="font-size-18 bold line-height-large "
                >
                <div class="flex-row justify-between">
                                    <div>
                                        本期模型构成因子赋值和说明    
                                    </div>
                                    <div>
                                      <el-select v-model="curshowNum" size="mini" v-if="activeFreq=='month' && productType=='default'" class="margin-right-10" style="width: 120px;">
                                            <el-option v-for="item of shownumList" :value="item.value" :label="item.label" :key="item.value"></el-option>
                                        </el-select>
                                        <el-select v-model="curmoveNum" size="mini" v-if="activeFreq=='month' && productType=='default'">
                                            <el-option v-for="item of movenumList" :value="item.value" :label="item.label" :key="item.value"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="margin-top-15 divisorinfotable">
                  <divisor-Tablecom :divisorinfo="divisorinfo" :productType="productType"  :target="targetId" :curmoveNum="curmoveNum"  :curshowNum="curshowNum" :productID="productID" :activeFreq="activeFreq"></divisor-Tablecom>
                </el-col>
              
              </el-row>
              <div class="border-top margin-tb-40"></div>
              <el-row v-show="productType=='default'">
                <el-col :span="24" class="font-size-18 line-height-large bold ">
                {{this.activeFreq=='week'?'周度':'月度'}}市场基本面监测表
                </el-col>
              </el-row>
              <el-row v-show="productType=='default'">
                <el-col :span="24" class="margin-top-15">
                  <market-monitoring-table :data="marketmonitor"></market-monitoring-table>
                </el-col>
              </el-row>
              <div class="border-top margin-tb-40"></div>
              <el-row type="flex" align="middle" :gutter="40">
                <el-col :span="24" class="font-size-18 bold margin-top-15">
                  市场综述：{{ reportDes.markettype }}
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="margin-top-15">
                  <div class="font-size-12 uetext" v-html="reportDes ? reportDes.marketdesc : ''"></div>
                </el-col>
              </el-row>
              <div class="border-top margin-tb-40"></div>
              <div
                v-for="(item, index) of colummList"
                :key="index"
                class=""
              >
                <div class="flex-row justify-between margin-top-15" :gutter="40">
                  <div class="font-size-18 bold margin-top-15">
                    {{ item.title }}:{{ item.type }}
                  </div>
                </div>
                <div
                  v-if="
                    columnChartdatalist.findIndex(
                      (o) => o.columnid == item.columnid
                    ) >= 0
                  "
                >
                  <div
                    v-for="(chartdetail, index) of columnChartdatalist.filter(
                      (o) => o.columnid == item.columnid
                    )"
                    :key="index"
                  >
                    
                    <Chart
                      :option="
                        columnChartdatalist.filter(
                          (o) => o.id == chartdetail.id
                        )[0].chartopt
                      "
                    ></Chart>
                  </div>
                </div>
                <el-row>
                  <el-col :span="24" class="margin-top-15">
                    <div class="font-size-12 uetext " v-html="item.desc"></div>
                  </el-col>
                </el-row>
                <div class="border-top margin-tb-40"></div>
              </div>
              
              <el-row>
                <el-col :span="24" class=" font-size-16">
                  <div class="font-size-18 bold ">相关文件和链接：</div>
                  <div>
                    <div
                      v-for="(item, index) of linkfileList"
                      :key="index"
                      class="link-text margin-top-10 font-size-14"
                    >
                      {{ item.reportOffline.reportName }}&nbsp;
                      <span @click="openfile($server + item.reportOffline.fileUrl)"   target="_blank"      class="link-text" >—点击查看</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <div></div>
            </div>
          </el-col>
        </el-row>
      </div>
   
    </div>
  </div>

  </template>
   <script>
  import Chart from "@/components/Chart";
  import MarketMonitoringTable from "../../components/MarketMonitoringTable.vue";
  import common from './common';
  import DivisorTablecom from './components/divisorTablecom.vue';
  export default {
    name: "reportshare",
    components: {
      Chart,
      MarketMonitoringTable,
        DivisorTablecom,
    },
    mixins: [common],
    data() {
      return {
        chartdatakey: "", //图表数据搜索关键词    
        options: [],
        productID: "",
        activeFreq: "week",
        targetId: "",
        reportid: 0,
        reportdate: "",
        reportinfo: {},
        marketmonitor: [],
        reportDes: {},
        //  columnNum:0,
        colummList: [],
        addchartVisiable: false,
        linkfileList: [],
        addlinkfile: false,
        chartdataList: [],
        chartdataListall: [],
        filecheckList: [],
        columnChartlist: [],
        activeColumn: {},
        offlinefileList: [],
        publishid:'',
        startCycle:'',//报告期
        reportTilte:''
    };
    },
    mounted() {
      if(this.$route.query.pid){
          this.productID=parseInt(this.$route.query.pid);
      }
      if(this.$route.query.freq){
          this.activeFreq=this.$route.query.freq;
      }
      if(this.$route.query.startCycle){
          this.startCycle=this.$route.query.startCycle;
          //console.log(this.startCycle)
          this.reportdate=this.startCycle;
      }
      
      this.init();
    },
    methods: {
        handledivisorTab() {}, 
        openfile(href){
        window.open(href, "_blank");
      },
      changeProduct(pid){
    //     this.targetId=this.options.filter(o=>o.productId==pid)[0]?this.options.filter(o=>o.productId==pid)[0].targetId:""
         const curprod=this.options.filter(o=>o.productId==pid);
         if(curprod.length>0){
                if(curprod[0].productType=="default"){
                    this.targetId=curprod[0].targetId
                }else{
                    this.targetId=0
                }
         }  
         this.curmoveNum=1;
         this.curshowNum=3;
         this.$instance.get("/forecastReport/checkExistModel?modelFreq="+this.activeFreq+"&productId="+this.productID+"").then((res) => {
            if(res.data.code==0){
              this.$nextTick(async ()=>{
                await this.getpublistID();
                this.getreportDetail();
                this.gethistoricalReview();
                this.getForecastResult();
                this.getDivisor();
                this.getMarketMonitor();
              })
            }else{
                this.cleardata()
            }
         }) 
        
           
      },
      async getpublistID(){
        //获取发布ID
        this.publishid='';
        this.reportTilte=''
        await this.$instance.get("/forecastReport/getPublishId", {
            params: {
              productId: this.productID,
              modelFreq: this.activeFreq,
              startCycle: this.startCycle
            },
          }).then(res=>{
            if(res.data.info){
                this.publishid=res.data.info.id;
                this.reportTilte=res.data.info.reportName;
                this.reportdate=res.data.info.startCycle?this.$dateFormat(res.data.info.startCycle,'yyyy-MM'):''
            }else{
              this.$message({
                message: '暂未找到相应报告，请重新选择产品！',
                type: 'warning',
                center:true,
                offset: Math.ceil(document.documentElement.clientHeight/2.4)
              });
            }
               
          })
      },
      
      async getproductlist() {
         //先获取权限中的产品列表
            await  this.$instance.get('/manage/getProductPowerWithCustom',{params:{moduleCode:"yu_ce_bao_gao_edit",isReport:1}}).then(res=>{
                if(res.data.code==0 && res.data.info){
                    this.options=res.data.info;
                    if(this.options[0] && this.options.findIndex(o=>o.productId==this.productID)<0){
                      this.$alert(`您暂无此产品权限`, '提示', {
                        customClass:"custom-msgbox",
                        confirmButtonText: '确定',
                        showClose:false,
                        type: 'warning'
                        })
                        this.productID= this.options[0].productId;
                        //this.targetId=this.options[0].targetId;
                        this.targetId=this.options[0].productType=="default"?this.options[0].targetId:0;
                    }else if(!this.options || this.options.length<=0){
                        this.$alert(`您暂无此模块的产品权限`, '提示', {
                        customClass:"custom-msgbox",
                        confirmButtonText: '确定',
                        showClose:false,
                        type: 'warning'
                        })
                        .then(() => {
                        this.$router.replace({
                            path:'/'
                        })
                        })
                    }else{
                      const curprod=this.options.filter(o=>o.productId==this.productID);
                      if(curprod.length>0){
                              if(curprod[0].productType=="default"){
                                  this.targetId=curprod[0].targetId
                              }else{
                                  this.targetId=0
                              }
                      } 
                    }
                }
            })
      },
  
      gethistoricalReview() {
        //历史回归
        this.historydata = {
          item: [],
          dataList: [],
        };
        this.$instance
          .get("/forecastReport/getPublishResult", {
            params: {
                publishId: this.publishid,
                moduleCode: 0
            },
          })
          .then((res) => {
            if (res.data.info) {
              this.historydata = res.data.info;
            }
          });
      },
      getForecastResult() {
        //预测结果
        this.resultdata = {
          item: [],
          dataList: [],
        };
        this.$instance
        .get("/forecastReport/getPublishResult", {
            params: {
                publishId: this.publishid,
                moduleCode: 1
            },
          })
          .then((res) => {
            if (res.data.info) {
              this.resultdata = res.data.info;
            }
          });
      },
      getreportDetail() {
        //获取报告详情
        this.reportinfo = {};
        this.reportDes = {};
        this.linkfileList = [];
        this.colummList=[];
        this.columnChartlist = [];
        this.chartdetailDatalist=[];
        this.$instance.get("/forecastReport/getPublishResult", {
            params: {
                publishId: this.publishid,
                moduleCode: 4
            },
          })
          .then((res) => {
            if (res.data.info) {
              this.reportinfo = res.data.info;
              this.reportDes = this.reportinfo && this.reportinfo.reportDescs[0]? this.reportinfo.reportDescs[0] : {};
              this.linkfileList = res.data.info.reportFileRelas;
              this.colummList = res.data.info.reportColumns;
              this.columnChartlist = res.data.info.reportColumnTables||[];
              for(let it of this.columnChartlist){
                                  this.getChartdetail(it.id);
              }
            }
          });
      },
      getDivisor() {
        //获取因子说明
        this.divisorinfo = {
          item: [],
          dataList: [],
        };
        this.$instance.get("/forecastReport/getPublishResult", {
            params: {
                publishId: this.publishid,
                moduleCode: 2
            },
          })
          .then((res) => {
            if (res.data.info) {
              if(this.productType=='default'){
                this.divisorinfo = res.data.info;
              }else{
                this.divisorinfo = {1:res.data.info};
              }
             
            }
          });
      },
      getMarketMonitor() {
        //获取市场基本检测表
        this.marketmonitor = [];
        this.$instance.get("/forecastReport/getPublishResult", {
            params: {
                publishId: this.publishid,
                moduleCode: 3
            },
          })
          .then((res) => {
            if (res.data.info) {
              this.marketmonitor = res.data.info;
            }
          });
      },
      
      changemarkettype(val) {
        this.reportDes.markettype = val;
        this.updateReportinfo();
      },
      savecolumnDes(index, val) {
        this.colummList[index].desc = val;
        this.updateColums(index);
      },
    
      getChartdetail(tableid){//获取图表详细信息
              this.$instance.get('/forecastReport/getTableDiagram?tableId='+tableid).then((res)=>{
                  if(res.data.code==0){
                      let num=this.chartdetailDatalist.findIndex(o=>o.id==tableid)
                      if(num<0){
                          this.chartdetailDatalist.push(res.data.info);
                      }else{
                          this.chartdetailDatalist[num]=res.data.info
                      }
                      
                  }
                  
              })
      },
      getUserdatafield() {
        this.chartdataList = [];
        this.chartdataListall = [];
        //获取图表左侧数据列表
        this.$instance
          .get("/forecastReport/getUserDataField?productId=" + this.productID)
          .then((res) => {
            this.chartdataList = res.data.info;
            this.chartdataListall = res.data.info;
          });
      },
      getfileList() {
        //获取所有可关联文件
        this.$instance
          .get("/reportOffline/getReportOfflineInfos?productId=" + this.productID)
          .then((res) => {
            this.offlinefileList = res.data.info;
          });
      },
      async init() {
        await this.getproductlist();
        
        this.$instance.get("/forecastReport/checkExistModel?modelFreq="+this.activeFreq+"&productId="+this.productID+"").then(async (res) => {//验证自定义产品是否开设报告
            if(res.data.code==0){
                 // this.getreportID();
                await this.getpublistID();
                this.getreportDetail();
                this.gethistoricalReview();
                this.getForecastResult();
                this.getDivisor();
                this.getMarketMonitor();
            }else{
                this.cleardata()
            }
        })
      
      },
    },
    computed:{
      swidth(){
        if(this.options.filter(o=>o.productId==this.productID).length>0){
            return this.options.filter(o=>o.productId==this.productID)[0].productName.length*13+50
        }else{
          return ''
        }
      }
    }
  };
  </script>
   <style lang="scss">
     @import 'viewpage.scss';
  </style>
   